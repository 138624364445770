import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import routes from "./config";
import { Styles } from "../styles/styles";

const Router = () => {
  const baseRouteUrl = "/:locale(en|en-au|zh|tr|in|sg|br|ko|zh-mo)?";
   
  return (
    <Suspense fallback={null}>
      <Styles />
      
      <Switch>
      <Route path="/" exact={true} component={lazy(() => import(`../pages/Promo`))} />
      <Route path={baseRouteUrl } exact={true} component={lazy(() => import(`../pages/Promo`))} />
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={baseRouteUrl + routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
      
    </Suspense>
  );
};

export default Router;
