const routes = [
  {
    path: [  "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: [  "/checkout"],
    exact: true,
    component: "Checkout",
  },
  {
    path: [  "/thankyou"],
    exact: true,
    component: "Thankyou",
  },
  {
    path: [  "/error"],
    exact: true,
    component: "Error",
  },
  {
    path: [  "/cancel"],
    exact: true,
    component: "Cancel",
  },
  {
    path: [  "/payment"],
    exact: true,
    component: "Payment",
  },
  {
    path: [  "/promo"],
    exact: true,
    component: "Promo",
  },
];

export default routes;
