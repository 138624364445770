import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend"; 

import translationEn from "./locales/en/translation.json";
import translationEs from "./locales/es/translation.json";
import translationZh from "./locales/zh/translation.json";
import translationTr from "./locales/tr/translation.json";

import translationIn from "./locales/in/translation.json";
import translationKo from "./locales/ko/translation.json"; 

const domainlang=()   => { 
  switch (window.location.hostname) {
    case 'www.tutorabcpayment.com':
      return "tr"
      break; 
    default:
        return "zh";
  }  
}


i18n
.use(HttpBackend) 
.use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: domainlang(),
    whitelist: ['zh','en','tr', 'in', 'ko','sg','br', 'zh-MO', 'en-AU'],
    detection: { 
      order: ['path', 'cookie','querystring' ],
        caches: ['cookie'], 
      lookupFromPathIndex: 0, 
    },
   backend:{
    backendOptions:[
      {expirationTime: 7 * 24 * 60 * 60 * 1000}
    ]
   },
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: {
        translations: translationEn,
      },
      es: {
        translations: translationEs,
      },
      zh: {
        translations: translationZh,
      },
      tr: {
        translations: translationTr,
      },
      in: {
        translations: translationIn,
      },
      ko: {
        translations: translationKo,
      }, 
      sg: {
        translations: translationEn,
      }, 
      br: {
        translations: translationEn,
      },      
      
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;